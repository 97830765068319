import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"
import IssueForm from "../components/forms/IssueForm"
import * as EmailerActions from "../actions/EmailerActions"

const IssuesPage = ({ data, actions, state }) => {
  const { contentfulPage } = data
  const { SendIssueEmail, SendIssueError } = actions
  const { issueSent, isSendingIssue } = state

  return (
    <Page
      zeroTop
      {...contentfulPage}
      seoPath="feedback"
      seoTitle="Rallista Feedback"
      seoDescription="Contact the Rallista support team. Ask questions that you can't find the answer to, give us suggestions or request features."
    >
      <FancyHeader
        title={"Give us Feedback"}
        subTitle={"Tell us what you think, good or bad."}
      />
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <IssueForm
              onSubmit={SendIssueEmail}
              onError={SendIssueError}
              sending={isSendingIssue}
              sent={issueSent}
            />
            <br />
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

IssuesPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    SendIssueEmail: PropTypes.func.isRequired,
    SendIssueError: PropTypes.func.isRequired,
  }),
  state: PropTypes.shape({
    issueSent: PropTypes.bool.isRequired,
    isSendingIssue: PropTypes.bool.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  state: state.emailer,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(EmailerActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(IssuesPage)

export const pageQuery = graphql`
  query IssuesPageQuery {
    contentfulPage(rallistaId: { eq: "feedback" }) {
      contentful_id
      seoTitle
      rallistaId
      seoDescription
    }
  }
`
